@import '@mri/scss-all/scss/variables/_all.scss';

@mixin color-theme-light() {
  --mri-theme-color-background: white;
  --mri-theme-color-text-header: #394754;
  --mri-theme-color-text-header-secondary: #394754;
  --mri-theme-border-style: 1px solid #909fae;
}

@mixin color-theme-grey() {
  --mri-theme-color-background: #f0f2f4;
  --mri-theme-color-text-header: #044d66;
  --mri-theme-color-text-header-secondary: #162029;
}

@mixin apply-default-color-theme-light() {
  body {
    @include color-theme-light;
  }
}

@mixin apply-default-color-theme-grey() {
  mri-shared-page-header {
    @include color-theme-grey;
  }
}
